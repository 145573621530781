.navbar{
    background: rgb(25, 25, 25);
    height: 5rem;
    width: 100vw;
    margin: 0 auto;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;

    font-family: 'Poppins', sans-serif;
}

.bar{
    height: 5rem;
    width: 100%;
    max-width: 10rem;
    position: absolute;
}

.logo {
    object-fit: cover;
    height: 4rem;
    width: 4rem;
}

.desktopMenuListItem{
    margin: 2rem;
    transition: color 1s ease-out 10ms;
    text-decoration: none;
    color: white;
}

.desktopMenuListItem:hover {
    color: #ec751a;
    padding-bottom: 0.5rem;
    border-bottom: 4px solid;
}

.desktopMenuListItem::after{
    content: '';
    width: 0;
    height: 4px;
    background: #ec751a;
    transition: width .3s;
}

.desktopMenuListItem:hover::after{
    width: 100%;
}

.desktopMenuImg{
    object-fit: cover;
    height: 1.5rem;
    width: 1.5rem;
    margin: 1rem;
    color:#ec751a;
}

.desktopMenuBtn{
    background: rgb(50,50,50);
    color: white;
    border: none;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    border-radius: 3rem;
    font-family: 'Poppins', sans-serif;
}

.desktopMenuBtn:hover{
    color: #ec751a;
    transition-duration: 3ms;
}


.active{
    color:#ec751a;
    padding-bottom: 0.5rem;
    border-bottom: 4px solid;
}

/* .navMenu {
    position: relative;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 10rem;
    background: rgb(50, 50, 50);
    border-radius: 1rem;
    align-items: center;
    transition: 0.4s;
} */

.navMenu{
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100vh;
    display:block;
    z-index: 98;
    background-color: rgb(50, 50, 50);
    padding-top: 120px;
    margin-top: 10vh;
    transition: 0.4s;
}

.navMenu.is-active {
    left: 0;
}

.listItem {
    color: white;
    display: block;
    width: fit-content;
    margin: 0 auto 16px;
    text-align: center;
    padding: 12px 16px;

}

.listItem:hover{
    color: #ec751a;
    border-bottom: 4px solid;
}

.mobileMenu {
    display: none;
    
}

.hamburger{
    position: relative;
    display: block;
    width: 35px;
    cursor: pointer;

    appearance: none;
    background: none;
    outline: none;
    border: none;

}

.hamburger .bar, .hamburger::after, .hamburger::before{
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    background-color: #ec751a;
    margin: 6px 0px;
    transition: 0.4s;
}

.hamburger.is-active:before {
    transform: rotate(-45deg) translate(-8px, 6px);

}

.hamburger.is-active:after {
    transform: rotate(45deg) translate(-8px, -8px);

}

.hamburger.is-active .bar {
    opacity: 0;
}


@media screen and (max-width:650px) {
    .mobileMenu{
        display: flex;
    }
    .desktopMenu {
        display: none;
    }
    .desktopMenuBtn{
        display: none;
    }
}

@media screen and (min-width:651px) {
    .hamburger{
        display: none;
    }
}