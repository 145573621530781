#intro {
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 75rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    font-family: 'Poppins', sans-serif;

}
/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #323232 #181818;
}

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
}

  *::-webkit-scrollbar-track {
    background: #181818;
}

  *::-webkit-scrollbar-thumb {
    background-color: hsl(0, 0%, 20%);
    border-radius: 10px;
    border: 3px solid #181818;
}

.bg{
    position: absolute;
    top: 10rem;
    z-index: -1;
    right: 0;
    object-fit: cover;
    height: 70vh;
}

.introContent {
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;   
    -webkit-text-stroke-width: .1px;
    -webkit-text-stroke-color: black;
}

.hello {
    font-size: 1.75rem;
    font-weight: 100;
}

.introName{
    color: orangered;
}

.introText {
    font-size: 3rem;
    font-weight: 500;
}

.introDescription{
    font-size: medium;
    font-weight: 100;
}

.btn {
    background: rgb(50, 50, 50);
    margin: 2rem 0;
    padding: 0rem 2rem;
    border: none;
    border-radius: 2rem;
    display: flex;
    padding: 1px 1rem;
    
}

.btnImg{
    padding: 0.5rem;
    height: 3rem;
}

.btnText{
    padding: 0.6rem 0.2rem;
    font-size: 1rem;
    color: white;
    font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 840px) {
    .bg{
        right: -20vw;
        filter: brightness(80%);

    }
    .introContent{
        font-size: 10vw;
    }
    .hello{
        font-size: 4.5vw;
    }

}